// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-8-reasons-to-choose-it-specialists-js": () => import("./../../../src/pages/8-reasons-to-choose-it-specialists.js" /* webpackChunkName: "component---src-pages-8-reasons-to-choose-it-specialists-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-backup-and-disaster-recovery-js": () => import("./../../../src/pages/backup-and-disaster-recovery.js" /* webpackChunkName: "component---src-pages-backup-and-disaster-recovery-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cloud-it-solutions-js": () => import("./../../../src/pages/cloud-it-solutions.js" /* webpackChunkName: "component---src-pages-cloud-it-solutions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-file-sync-and-share-js": () => import("./../../../src/pages/file-sync-and-share.js" /* webpackChunkName: "component---src-pages-file-sync-and-share-js" */),
  "component---src-pages-help-desk-js": () => import("./../../../src/pages/help-desk.js" /* webpackChunkName: "component---src-pages-help-desk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-managed-services-js": () => import("./../../../src/pages/managed-services.js" /* webpackChunkName: "component---src-pages-managed-services-js" */),
  "component---src-pages-network-cabling-js": () => import("./../../../src/pages/network-cabling.js" /* webpackChunkName: "component---src-pages-network-cabling-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-special-projects-js": () => import("./../../../src/pages/special-projects.js" /* webpackChunkName: "component---src-pages-special-projects-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-voip-and-connectivity-js": () => import("./../../../src/pages/voip-and-connectivity.js" /* webpackChunkName: "component---src-pages-voip-and-connectivity-js" */),
  "component---src-pages-web-solutions-js": () => import("./../../../src/pages/web-solutions.js" /* webpackChunkName: "component---src-pages-web-solutions-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

